import {buildSchema} from "@camberi/firecms";
import {Notification} from '../types/notification.types'
import {notificationPreview} from "../components/Notification-preview";


export const notificationSchema = buildSchema<Notification>({
    name: 'Notification',
    properties: {
        title: {
          title: 'Titel',
            validation: {
              required: true,
              requiredMessage : 'Dieses Feld darf nicht leer sein.',
              min: 1,
            },
          dataType: 'string'
        },
        message: {
            title: 'Nachricht',
            dataType: 'string',
            validation: {
                required: true,
                requiredMessage : 'Dieses Feld darf nicht leer sein.',
                min: 1,
            }
        },
        autoNav: {
            title: 'Automatische Navigation',
            dataType: "boolean",
            description: "Beim Klicken auf die Push-Notification wird der Benutzer auf die Detailseite des Events weitergeleitet."
        },
        send: {
            config: {
                Field: notificationPreview
            },
            title: '',
            dataType: 'map',
        }
    },
})