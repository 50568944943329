import { buildSchema, buildProperty } from '@camberi/firecms'
import { Retro } from '../types/retro.types'

export const RetroSchema = buildSchema<Retro>({
    name: 'Retrospective',
    properties: {
        title: {
            title: 'Titel',
            validation: { required: true },
            dataType: 'string',
        },
        description: {
            title: 'Beschreibung',
            validation: { required: true },
            dataType: 'string',
        },
        buttonText: {
            title: 'Button Text',
            dataType: 'string',
        },
        buttonLink: {
            title: 'Button Link',
            dataType: 'string',
        },
        dateStart: {
            title: 'Start Rückblick',
            validation: { required: true },
            dataType: 'timestamp',
        },
        published: {
            title: 'Sichtbar',
            dataType: 'boolean',
        }
    },
})
