import {Button} from "@mui/material";
import {getAuth} from "@firebase/auth";
import {FieldProps} from "@camberi/firecms";
import toast, { Toaster } from 'react-hot-toast';


export function notificationPreview({context}: FieldProps<any>) {
    const TITLE = context.values.title;
    const MESSAGE = context.values.message;


    async function sendPushNotification(){
        const token = await getAuth().currentUser?.getIdToken();
        const fetchUrl = process.env.REACT_APP_PUSH;

        if(validateInput() && fetchUrl){
            const fetchPush = fetch(fetchUrl, {
                method: 'POST',
                headers: {
                    "Authorization": 'Bearer '+token
                },
                body: JSON.stringify({
                    title: context.values.title,
                    message: context.values.message,
                    entityId: window.location.pathname.split('/')[3],
                    autoNav: context.values.autoNav
                }),
            });

            await toast.promise(fetchPush, {
                loading: 'Benachrichtigung wird vorbereitet.',
                success: 'Benachrichtigung versendet',
                error: 'Ein Fehler ist aufgetreten.'
            })

        }else{
            toast.error('Push cannot be send. Please validate your input.')
        }
    };


    function validateInput(){
        return TITLE && MESSAGE && TITLE.length > 1 && MESSAGE.length > 1;
    }

    return <>
        <div style={{paddingBottom: "32px", paddingTop: 0}}><Button variant="contained" onClick={() => sendPushNotification()}>Benachrichtigung versenden</Button>
            <Toaster position="top-center"/>
        </div>
    </>
}