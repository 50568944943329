import {NavigationBuilder, } from "@camberi/firecms";
import {eventsCollection} from "../collections/events.collection";
import {usersCollection} from "../collections/users.collection";
import {settingsCollection} from "../collections/settings.collection";
import {settingsPublicCollection} from "../collections/settingsPublic.collection";
import {pushTopicsCollection} from "../collections/pushTopics.collection";
import {rolesCollection} from "../collections/roles.collection";
import {loggingCollection} from "../collections/logging.collection";
import { addressesCollection } from "../collections/addresses.collection";
import { staffCollection } from "../collections/staff.collection";
import { partnersCollection } from "../collections/partners.collection";
import { retroCollection } from "../collections/retro.collection";


export const navigation: NavigationBuilder =
    async () => {
        return {
            collections: [
                addressesCollection,
                eventsCollection,
                usersCollection,
                settingsCollection,
                settingsPublicCollection,
                pushTopicsCollection,
                rolesCollection,
                loggingCollection,
                staffCollection,
                partnersCollection,
                retroCollection
            ],
        }
    }
