import { buildSchema } from '@camberi/firecms'
import { Role } from '../types/role.types'

export const roleSchema = buildSchema<Role>({
    name: 'Rolle',
    customId: true,
    properties: {
        name: {
            title: 'Name',
            dataType: 'string',
            columnWidth: 100,
        },
        canLogIn: {
            title: '🚪 App Login',
            dataType: 'boolean',
            description:
                'Bestimmt, ob sich ein User in der PV App anmelden kann.',
            longDescription:
                'Wenn ein User diese Berechtigung verliert, wird er direkt aus der App ausgeloggt!',
        },
        canSeeAdminStatistics: {
            title: '✨ Admin Statistik anzeigen',
            dataType: 'boolean',
            description:
                'Bestimmt, ob ein Benutzer Admin Statistiken in der App sehen kann',
        },
        events_read: {
            title: '🔍 events',
            dataType: 'boolean',
        },
        events_write: {
            title: '📝 events',
            dataType: 'boolean',
        },
        pushTopics_read: {
            title: '🔍 pushTopics',
            dataType: 'boolean',
        },
        pushTopics_write: {
            title: '📝 pushTopics',
            dataType: 'boolean',
        },
        roles_read: {
            title: '🔍 roles',
            dataType: 'boolean',
        },
        roles_write: {
            title: '📝 roles',
            dataType: 'boolean',
        },
        settings_read: {
            title: '🔍 settings',
            dataType: 'boolean',
        },
        settings_write: {
            title: '📝 settings',
            dataType: 'boolean',
        },
        settingsPublic_read: {
            title: '🔍 settingsPublic',
            dataType: 'boolean',
        },
        settingsPublic_write: {
            title: '📝 settingsPublic',
            dataType: 'boolean',
        },
        users_read: {
            title: '🔍 users',
            dataType: 'boolean',
        },
        users_read_own: {
            title: '🔍👤 users',
            dataType: 'boolean',
        },
        users_write: {
            title: '📝 users',
            dataType: 'boolean',
        },
        logging_read: {
            title: '🔍 Logs',
            dataType: 'boolean',
        },
        logging_write: {
            title: '📝 Logs',
            dataType: 'boolean',
        },
        download_read: {
            title: '🔍 downloads',
            dataType: 'boolean',
        },
        download_write: {
            title: '📝 downloads',
            dataType: 'boolean',
        },
        retro_read: {
            title: '🔍 retrospective',
            dataType: 'boolean',
        },
        retro_write: {
            title: '📝 retrospective',
            dataType: 'boolean',
        },
        partner_read: {
            title: '🔍 partners',
            dataType: 'boolean',
        },
        partner_write: {
            title: '📝 partners',
            dataType: 'boolean',
        },
        location_read: {
            title: '🔍 locations',
            dataType: 'boolean',
        },
        location_write: {
            title: '📝 locations',
            dataType: 'boolean',
        },
        addresses_read: {
            title: '🔍 addresses',
            dataType: 'boolean',
        },
        addresses_write: {
            title: '📝 addresses',
            dataType: 'boolean',
        },
        attendees_read: {
            title: '🔍 attendees',
            dataType: 'boolean',
        },
        attendees_write: {
            title: '📝 attendees',
            dataType: 'boolean',
        },
    },
})
