 import { buildProperty, buildSchema } from '@camberi/firecms'
import { Address } from '../types/address.type'
import { GeopointField } from '../components/geopoint-field'
import { GeopointPreview } from '../components/geopoint-preview'

export const addressSchema = buildSchema<Address>({
    name: 'Addresse',
    properties: {
        name: {
            title: 'Standort Name',
            validation: { required: true },
            dataType: 'string',
            columnWidth: 400,
        },
        street: {
            title: 'Strasse',
            validation: { required: true },
            dataType: 'string',
        },
        plz: {
            title: 'PLZ',
            validation: { required: true },
            dataType: 'number',
        },
        city: {
            title: 'Ort',
            validation: { required: true },
            dataType: 'string',
        },
        geoPoint: {
            config: {
                Field: GeopointField,
                Preview: GeopointPreview,
            },
            title: 'Geopoint',
            dataType: 'geopoint',
        },
        published: ({ values }) =>
            buildProperty({
                title: 'Sichtbar',
                dataType: 'boolean',
                columnWidth: 130,
            }),
    },
})
