import { buildSchema, buildProperty } from '@camberi/firecms'
import { User } from '../types/user.types'

export const userSchema = buildSchema<User>({
    name: 'User',
    properties: {
        lastname: {
            title: 'Nachname',
            validation: { required: true },
            dataType: 'string',
        },
        firstname: {
            title: 'Vorname',
            validation: { required: true },
            dataType: 'string',
        },
        email: {
            title: 'Email',
            validation: { required: true },
            dataType: 'string',
        },
        phone: {
            title: 'Telefonnummer',
            dataType: 'string',
        },
        company: {
            title: 'Unternehmen',
            dataType: 'string',
        },
        linkedIn: {
            title: 'LinkedIn Profil',
            dataType: 'string',
        },
        roles: {
            dataType: 'array',
            title: 'Rollen',
            validation: { required: true },
            of: {
                dataType: 'reference',
                path: 'roles',
            },
        },
        isAdmin: ({ values }) =>
            buildProperty({
                title: '⚠️ Admin',
                description: 'Adminrechte für Zugang zum Backend. Vorsicht!',
                dataType: 'boolean',
                columnWidth: 130,
            }),
    },
})
