import { buildCollection } from '@camberi/firecms'
import { partnerSchema } from '../schemas/partner.schema'

export const partnersCollection = buildCollection({
    group: 'Partner',
    path: 'partners',
    schema: partnerSchema,
    name: 'Partners',
    defaultSize: 'xs',
    initialSort: ['name', 'desc'],
    pagination: true,
    textSearchEnabled: true,
    permissions: ({ authController }) => ({
        edit: true,
        create: true,
        delete: true,
    }),
    callbacks: {
        onPreSave: async (props) => {
            return {
                ...props.values,
                lastEditor: props.context.authController.user.uid,
            }
        },
    },
})
