import { buildSchema, buildProperty } from '@camberi/firecms'
import { RetroRef } from '../types/retro.types'
import {retroEnum} from "../enums/retro.enum";
export const RetroRefSchema = buildSchema<RetroRef>({
    name: 'Retrospective',
    customId: retroEnum,
    properties: {
        retro: {
            dataType: 'array',
            title: 'Rückblick',
            description: 'Rückblick',
            validation: { required:true},
            of: {
                dataType: 'reference',
                path: 'retrospective',
            },
        },
        published: {
            title:'Sichtbar',
            dataType: 'boolean',
        }
    },
})
