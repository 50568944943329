import { buildSchema, buildProperty } from '@camberi/firecms'
import { ProgramPoint } from '../types/programPoint.types'

// @ts-ignore
export const programPointSchema = buildSchema<ProgramPoint>({
    name: 'programPoint',
    properties: {
        title: {
            title: 'Titel',
            validation: { required: true },
            dataType: 'string',
        },
        timeStart: {
            title: 'Datum: Start',
            validation: { required: true },
            dataType: 'timestamp',
        },
        timeEnd: {
            title: 'Datum: Ende',
            validation: { required: true },
            dataType: 'timestamp',
        },
        speaker: {
            dataType: 'reference',
            path: 'staff',
            title: 'Sprecher',
        },
        location: {
            title: 'Ort',
            dataType: 'string',
        },
        description: {
            title: 'Beschreibung',
            dataType: 'string'
        },
        published: {
            title: 'Sichtbar',
            dataType: 'boolean',
        }
    },
})
