import { buildSchema, buildProperty } from '@camberi/firecms'
import { Speaker } from '../types/speaker.types'
import {speakerEnum} from "../enums/speaker.enum";
export const speakerSchema = buildSchema<Speaker>({
    name: 'Speaker',
    customId: speakerEnum,
    properties: {
        staff: {
            dataType: 'array',
            title: 'Event Personal',
            validation: { required: true},
            description: 'Event Personal',
            of: {
                dataType: 'reference',
                path: 'staff'
            },
        },
        published: {
            title: 'Sichtbar',
            dataType: 'boolean',
        },
    },
})
