import { buildSchema, buildProperty } from '@camberi/firecms'
import { Partner } from '../types/partner.types'

export const partnerSchema = buildSchema<Partner>({
    name: 'Partner',
    properties: {
        name: {
            title: 'Name',
            validation: { required: true },
            dataType: 'string',
        },
        websiteLink: {
            title: 'Link zu Website',
            validation: { required: true },
            dataType: 'string',
        },
        image: buildProperty({
            title: 'Bild',
            dataType: 'string',
            validation: { required: true },
            config: {
                storageMeta: {
                    mediaType: 'image',
                    storagePath: 'images/partners',
                    acceptedFiles: ['image/*'],
                },
            },
        }),
        published: {
            title: 'Sichtbar',
            dataType: 'boolean',
        },
    },
})
