import { buildSchema, buildProperty } from '@camberi/firecms'
import { Attendees } from '../types/attendee.type'

export const attendeesSchema = buildSchema<Attendees>({
    name: 'Attendee',
    properties: {
        users: {
            dataType: 'array',
            validation: { max: 1 },
            title: 'Teilnehmer',
            description: 'Teilnehmer',
            of: {
                dataType: 'reference',
                path: 'users',
            },
        }
    },
})
