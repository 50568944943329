import { GeoPoint } from "@camberi/firecms";
import { Address } from "../types/address.type";

export function fetchGeopoint(address: Partial<Address>) {

    const text = [address.street, address.plz, address.city].filter(part => !!part).join(', ');

    const requestOptions = {
        method: 'GET',
      };
      
      return fetch(`https://api.geoapify.com/v1/geocode/search?text=${encodeURIComponent(text)}&apiKey=${process.env.REACT_APP_GEOCODING_APIKEY}&format=json`, requestOptions)
        .then(response => response.json())
        .then(result => {
            const geopoint = result.results[0];
            return new GeoPoint(geopoint.lat, geopoint.lon);
        })
}