import { buildCollection } from '@camberi/firecms'
import { RetroSchema } from '../schemas/retro.schema'

export const retroCollection = buildCollection({
    group: 'Events',
    path: 'retrospective',
    schema: RetroSchema,
    name: 'Rückblick',
    defaultSize: 's',
    initialSort: ['dateStart', 'desc'],
    pagination: true,
    textSearchEnabled: true,
    permissions: ({ authController }) => ({
        edit: true,
        create: true,
        delete: true,
    }),
    callbacks: {
        onPreSave: async (props) => {
            return {
                ...props.values,
                lastEditor: props.context.authController.user.uid,
            }
        },
    },
})
