import { buildSchema, buildProperty } from '@camberi/firecms'
import { Staff } from '../types/staff.types'

export const staffSchema = buildSchema<Staff>({
    name: 'Staff',
    properties: {
        lastname: {
            title: 'Nachname',
            validation: { required: true },
            dataType: 'string',
        },
        firstname: {
            title: 'Vorname',
            validation: { required: true },
            dataType: 'string',
        },
        image: buildProperty({
            // The `buildProperty` method is an utility function used for type checking
            title: 'Bild',
            dataType: 'string',
            validation: { required: true },
            config: {
                storageMeta: {
                    mediaType: 'image',
                    storagePath: 'images/staff',
                    acceptedFiles: ['image/*'],
                },
            },
        }),
        published: {
            title: 'Sichtbar',
            dataType: 'boolean',
        },
        description: {
            title: 'Beschreibung',
            dataType: 'string',
        },
        title: {
            title: 'Titel',
            dataType: 'string',
        }
    },
})
