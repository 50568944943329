import { buildCollection } from '@camberi/firecms'
import { eventSchema } from '../schemas/event.schema'
import { attendeesSchema } from '../schemas/attendee.schema'
import { locationSchema } from '../schemas/location.schema'
import { speakerSchema } from '../schemas/speaker.schema'
import { programPointSchema } from '../schemas/programPoint.schema'
import { downloadSchema } from '../schemas/download.schema'
import { RetroRefSchema } from '../schemas/retroRef.schema'
import { eventPartnerSchema } from '../schemas/eventPartner.schema'
import { localitySchema } from '../schemas/locality.schema'
import {notificationSchema} from "../schemas/notification.schema";


export const eventsCollection = buildCollection({
    group: 'Events',
    path: 'events',
    schema: eventSchema,
    defaultSize: 'xs',
    initialSort: ['dateStart', 'desc'],
    pagination: true,
    name: 'Events',
    permissions: ({ authController }) => ({
        edit: true,
        create: true,
        delete: true,
    }),
    excludedProperties: ['sendPushNotificationToUser'],
    subcollections: [
        {
            group: 'Notifications',
            path: 'notifications',
            schema: notificationSchema,
            name: 'Benachrichtigungen',
            defaultSize: 'xs'
        },
        {
            group: 'Events',
            path: 'location',
            schema: locationSchema,
            name:'Standorte',
            defaultSize: 'xs'
        },
        {
            group: 'Events',
            path: 'locality',
            schema: localitySchema,
            name:'Lokalität',
            defaultSize: 'xs'
        },
        {
            group: 'Events',
            path: 'attendees',
            schema: attendeesSchema,
            name:'Teilnehmer',
            defaultSize: 'xs',
        },
        {
            group: 'Events',
            path: 'partners',
            schema: eventPartnerSchema,
            name:'Partner',
            defaultSize: 'xs'
        },
        {
            group: 'Events',
            path: 'speaker',
            schema: speakerSchema,
            name:'Sprecher',
            defaultSize: 'xs'
        },
        {
            group: 'Events',
            path: 'programPoints',
            schema: programPointSchema,
            name:'Programm Punkte',
            defaultSize: 'xs',
            initialSort: ['timeStart', 'asc'],
        },
        {
            group: 'Events',
            path: 'downloads',
            schema: downloadSchema,
            name:'Downloads',
            defaultSize: 'xs'
        },
        {
            group: 'Events',
            path: 'retrospective',
            schema: RetroRefSchema,
            name:'Rückblick',
            defaultSize: 'xs'
        }
    ],
    callbacks: {
        onPreSave: async (props) => {
            return {
                ...props.values,
                lastEditor: props.context.authController.user.uid,
            }
        },
    },
})
