import { buildCollection } from '@camberi/firecms'
import { staffSchema } from '../schemas/staff.schema'

export const staffCollection = buildCollection({
    group: 'Event Personal',
    path: 'staff',
    schema: staffSchema,
    name: 'Personal',
    defaultSize: 's',
    initialSort: ['lastname', 'asc'],
    pagination: true,
    textSearchEnabled: true,
    permissions: ({ authController }) => ({
        edit: true,
        create: true,
        delete: true,
    }),
    callbacks: {
        onPreSave: async (props) => {
            return {
                ...props.values,
                lastEditor: props.context.authController.user.uid,
            }
        },
    },
})
