import {createCMSDefaultTheme} from "@camberi/firecms";

let colorScheme: 'light' | 'dark' = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark': 'light'

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
    colorScheme = e.matches ? "dark" : "light";
});

export let defaultTheme = createCMSDefaultTheme({
    mode: colorScheme,
    primaryColor:
        process.env.REACT_APP_ENV == 'PRODUCTION'
            ? '#f54281'
            : '#1e81b0',
    secondaryColor:
        process.env.REACT_APP_ENV == 'PRODUCTION'
            ? '#f54281'
            : '#1e81b0',
    fontFamily: 'Roboto, sans-serif',
})