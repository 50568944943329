import { buildSchema } from '@camberi/firecms'
import { Location } from '../types/location.type'
import {locationEnum} from "../enums/location.enum";

export const locationSchema = buildSchema<Location>({
    name: 'Addresses',
    customId: locationEnum,
    properties: {
        address: {
            dataType: 'reference',
            path: 'addresses',
            title: 'Adresse',
        },
        published: {
            dataType: 'boolean',
            title: 'Sichtbar'
        }
    },
})
