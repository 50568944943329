import { buildSchema, buildProperty } from '@camberi/firecms'
import { eventPartner } from '../types/eventPartner.type'
import {partnerEnum} from "../enums/partner.enum";

export const eventPartnerSchema = buildSchema<eventPartner>({
    name: 'Partner',
    customId: partnerEnum,
    properties: {
        partner: {
            dataType: 'array',
            title: 'Partner',
            description: 'Partner',
            of: {
                dataType: 'reference',
                path: 'partners',
            },
        }
    },
})
