import { buildSchema, buildProperty } from '@camberi/firecms'
import { Download } from '../types/download.types'

export const downloadSchema = buildSchema<Download>({
    name: 'Download',
    properties: {
        title: {
            title: 'Titel',
            validation: { required: true },
            dataType: 'string',
        },
        matIconName: {
            title: 'File Typ',
            validation: { required: true },
            dataType: 'string',
            config: {
                enumValues: {
                    PDF: 'PDF',
                    Word: 'Word',
                    PowerPoint: 'PowerPoint',
                    Excel: 'Excel',
                },
            },
            columnWidth: 150
        },
        file: buildProperty({
            // The `buildProperty` method is an utility function used for type checking
            title: 'File',
            dataType: 'string',
            validation: { required: true },
            config: {
                storageMeta: {
                    storagePath: 'downloads/events',
                    acceptedFiles: ['*'],
                },
            },
        }),
        published: {
            title: 'Sichtbar',
            dataType: 'boolean',
        }
    },
})
