import { buildSchema, buildProperty } from '@camberi/firecms'
import { AppSetting } from '../types/appsetting.types'

export const appsettingSchema = buildSchema<AppSetting>({
    name: 'Events-App Settings',
    properties: {
        aboutEventApp: {
            dataType: 'string',
            title: 'Über die App',
            description: 'Über die Event App',
        },
        featuredEvent: {
            dataType: 'array',
            title: 'Featured Event',
            description: 'Featured Event auf der Startseite',
            validation: {max: 1},
            of: {
                dataType: 'reference',
                path: 'events',
            },
        },
        showFeaturedEvent: {
            dataType: 'boolean',
            title: 'Featured Event sichtbar',
        },
        showNextEvent: {
            dataType: 'boolean',
            title: 'Nächstes Event sichtbar'
        }
    },
})
