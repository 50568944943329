import { buildSchema, buildProperty } from '@camberi/firecms'
import { Locality } from '../types/locality.types'
import {localityEnum} from "../enums/locality.enum";

export const localitySchema = buildSchema<Locality>({
    name: 'Lokalität',
    properties: {
        title: {
            title: 'Titel',
            validation: { required: false },
            dataType: 'string',
            config: {
                markdown: true,
            },
        },
        image: buildProperty({
            // The `buildProperty` method is an utility function used for type checking
            title: 'Bild',
            dataType: 'string',
            validation: { required: false },
            config: {
                storageMeta: {
                    mediaType: 'image',
                    storagePath: 'images/locality',
                    acceptedFiles: ['image/*'],
                },
            },
        }),
        description: {
            title: 'Beschreibung',
            validation: { required: false },
            dataType: 'string',
            config: {
                markdown: true,
            },
        },
        published: {
            title: 'Sichtbar',
            validation: { required: false },
            dataType: 'boolean',
        },
    },
})
